//import AdComponent from './AdComponent';
import {Router, BrowserRouter, Link, Routes, Route} from 'react-router-dom';
import Footer from '../Footer';
import NavBar from '../NavBar';
import BannerAd from '../BannerAd';
import SquareAd from '../SquareAd';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Card from '../Card';
import '../Card.css'
import '@coreui/coreui/dist/css/coreui.min.css'
import CFL from './CFL';




 function Home () {
    const [mlb, setMLB] = useState([]);
    const [nfl, setNFL] = useState([]);
    const [ncaaf, setNCAAF] = useState([]);
    const [cfl, setCFL] = useState([]);
    const [fifa, setFIFA] = useState([]);
    const [premier, setPremier] = useState([]);
    const [laLiga, setLaLiga] = useState([]);
    const [bundesLiga, setBundesLiga] = useState([]);
    const [serieA, setSerieA] = useState([]);
    const [champions, setChampions] = useState([]);
    const [nba, setNBA] = useState([]);
    const [wnba, setWNBA] = useState([]);
    //const [mma, setMMA] = useState([]);
    const [total, setTotal] = useState([])
    const apiKey = '143a0dc60904a41ee2ab555c349efb95'

  const nflKey = 'americanfootball_nfl' // *Was upcoming before* use the sport_key from the /sports endpoint below, or use 'upcoming' to see the next 8 games across all sports
  const ncaafKey = 'americanfootball_ncaaf'
  const cflKey = 'americanfootball_cfl'

  const mlbKey = 'baseball_mlb'

  const fifaKey = 'soccer_fifa_world_cup'
  const premierKey = 'soccer_epl'
  const laLigaKey = 'soccer_spain_la_liga'
  const bundesLigaKey = 'soccer_germany_bundesliga'
  const serieAKey = 'soccer_italy_serie_a'
  const championsKey = 'soccer_uefa_champs_league'

  const nbaKey = 'basketball_nba'
  const wnbaKey = 'basketball_wnba'

  //const mmaKey = 'mma_mixed_martial_arts'
  
  const regions = 'us' // uk | us | eu | au. Multiple can be specified if comma delimited
  
  const markets = 'h2h,spreads,totals' // h2h | spreads | totals. Multiple can be specified if comma delimited
  
  const oddsFormat = 'american' // decimal | american
  
  const dateFormat = 'unix' // iso | unix
    useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${mlbKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setMLB(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${nflKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
        
          setNFL(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
         
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${cflKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
        
          setCFL(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
         
      })
      },[])
    
      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${ncaafKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setNCAAF(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${fifaKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setFIFA(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${premierKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
        
          setPremier(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
         
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${laLigaKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
        
          setLaLiga(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
         
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${bundesLigaKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setBundesLiga(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${serieAKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setSerieA(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${championsKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setChampions(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${nbaKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setNBA(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])

      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${wnbaKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
          
          setWNBA(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
       
      })
      },[])
/*
      useEffect(()=>{
        axios.get(`https://api.the-odds-api.com/v4/sports/${mmaKey}/odds`, {
          params: {
              apiKey,
              regions,
              markets,
              oddsFormat,
              dateFormat,
          }
      }
      )
      .then(response => {
          // response.data.data contains a list of live and 
          //   upcoming events and odds for different bookmakers.
          // Events are ordered by start time (live events are first)
          //console.log(JSON.stringify(response.data, null, 2))\
        
          setMMA(response.data)
      
      })
      .catch(error => {
          console.log('Error status', error.response.status)
         
      })
      },[])
     */
      const odds = [...nfl, ...ncaaf, ...cfl, ...mlb, ...premier, ...nba, ...wnba];

  // odds.sort((a, b) => (a.commenceTime > b.commenceTime) ? 1 : -1)
  
  odds.sort((a, b) => {
    return a.commence_time - b.commence_time;
  });

        
    return (
        <>
        <NavBar />
        <BannerAd />
        <div className="App">
        
        {odds && odds.map((team) =>(
            <>
        <Card className='card' key={team.id} awayTeam={team.away_team} homeTeam={team.home_team} bookMakers={team.bookmakers} commenceTime={team.commence_time} sportKey={team.sport_key}>
        </Card>
        </>)
        )}
        </div>
    <Footer/>
        </>
        )}
export default  Home;

 
