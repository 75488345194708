import NavBar from '../NavBar';
import Footer from '../Footer';
import './Advert.css';
import {Link} from 'react-router-dom';
import {CContainer, CRow, CCol} from '@coreui/react';
import {CForm,CFormInput,CFormLabel,CInputGroup,CInputGroupText,CFormSelect,CFormCheck,CFormFeedback,CButton,CFormTextarea,CToast,CToastBody,CToastClose, CToaster,CImage} from '@coreui/react';
import { useState,useRef } from 'react';
import { send } from 'emailjs-com';

const Advert = () => {
  const [toast, addToast] = useState(0);
  const toaster = useRef();
  const exampleToast = (
  <CToast autohide={false} visible={true} className="align-items-center" color='success'>
    <div className="d-flex">
      <CToastBody>Your form has been submitted,<br></br>thank you for contacting us!</CToastBody>
      <CToastClose className="me-2 m-auto" />
    </div>
  </CToast>
  );
    const handleSubmit = (e) => {
    addToast(exampleToast)
    e.preventDefault();
    e.stopPropagation()
    send(
    "service_f9si1fd",
    "template_co32u8m",
    toSend,
    'V-ykJ5OOZFFcTy-vH',
  );
}
const [toSend, setToSend] = useState({
  fname: '',
  lname: '',
  company: '',
  email: '',
  phone:'',
  region:'',
  text:'',
});

const handleChange = (e) => {
  setToSend({ ...toSend, [e.target.name]: e.target.value });
};

    return (
        <>
        <NavBar /> 
        <CContainer>
        <h1 align='center'>Advertising Opportunities on SportsOdds.com</h1>
        <CContainer fluid align='center' className='example'>
            <CImage fluid src='images/ad-example.png'/>
            </CContainer>
            <CRow>
                <CCol md={6}>
        <section className = 'section'>
            <br></br>
            <h3>Top Home Page Banner Ad</h3>
            <p>
                This ad is self explanatory. In that its the primary ad seen when the home page is loaded.
                Size details are printed within the example (728px * 90px). Right below this banner ad is the next game/match to be played. 
                The advertiser link for a Home Page Leaderboard ad must be less than 50 character or less in length.
            </p>
            <br></br>
            <h3>Box Ads</h3>
            <p>
                Box Ads are squares that appear under the game cards. Size details are printed in the example (200px * 200px).
                Box ad positions 1st and 2nd appear under the first game card. Box ad positions 3rd and
                4th appear under the second game card. At this time there are only 4 box ad positions.
                The advertiser link for a box ad must be less than 50 character or less in length.
            </p> 
            <br></br>
            <h3>Featured Book Positions</h3>
            <p>
                These are Logos that produce a consumer impression. The three Featured
                Book Positions exist in each game card prior to the viewer clicking "More Books". When the viewer clicks upon the “More Books” button in a
                particular match box, as many as twenty or so books with their respective lines will appear.
                However, prior to a viewer clicking More Books button there are only three bookmakers that
                appear in all the homepage displayed game cards.
            </p>
            <br></br>
            <h3>Region Based Home Page</h3>
            <p>
                There is a Home Page unique to each marketplace. The marketplaces are US, UK, EU, and
                AU. Prices for ads in each market vary. Servers for each market are located
                geographically to provide the best response times possible to viewers.
            </p>
        </section>
        </CCol>
        <CCol md={6} className='contactform'>
            <CForm
                className="row g-3"
                onSubmit={handleSubmit}
            >
        <CCol md={6}>
        <CFormInput
            type="text"
            name='fname'
            placeholder="First Name Here..."
            feedbackValid="Looks good!"
            id="validationCustomFName"
            label="First name (Required)"
            value={toSend.fname}
            onChange={handleChange}
            required
        />
        </CCol>
        <CCol md={6}>
        <CFormInput
            type="text"
            name='lname'
            placeholder="Last Name Here..."
            feedbackValid="Looks good!"
            id="validationCustomLName"
            label="Last name (Required)"
            value={toSend.lname}
            onChange={handleChange}
            required
        />
        </CCol>
        <CCol md={12}>
      <CFormInput
        type="text"
        name='company'
        placeholder="Company you are representing"
        feedbackValid="Looks good!"
        id="validationCustomCompany"
        label="Company (Required)"
        value={toSend.company}
        onChange={handleChange}
        required
      />
      </CCol>
      <CCol md={12}>
        <CFormInput
          type="text"
          name='email'
          placeholder="email@example.com"
          feedbackValid="Please choose a username."
          id="validationCustomEmail"
          label="Email (Required)"
          value={toSend.email}
          onChange={handleChange}
          required
        />
        </CCol>
    <CCol md={6}>
      <CFormInput
        type="text"
        name='phone'
        placeholder='123-456-7890'
        id="validationCustomPhone"
        label="Phone Number"
        value={toSend.phone}
        onChange={handleChange}
      />
    </CCol>
    <CCol md={6}>
      <CFormInput
        type='text'
        name='region'
        placeholder='US, UK, EU, or AU'
        id="validationCustomRegion"
        label="Region (Required)"
        value={toSend.region}
        onChange={handleChange}
        required
      />
    </CCol>
    <CCol md={12}>
    <CFormTextarea
    id="exampleFormControlTextarea1"
    name='text'
    label="What ad opportunities interest you? (Required)"
    rows="3"
    placeholder="Answer Here..."
    value={toSend.text}
    onChange={handleChange}
    required
  />
    </CCol>
    <CCol xs={12}>
      <CButton color="info" variant='outline'type="submit">
        Submit form
      </CButton>
      <CToaster ref={toaster} push={toast} placement="bottom-end" />
    </CCol>
  </CForm>
        </CCol>
        </CRow>
        </CContainer>
        <Footer />
        </>
    );
};

export default Advert;