import NavBar from '../NavBar';
import Footer from '../Footer';
import {CContainer, CButton} from '@coreui/react';
import {Link} from 'react-router-dom';
const Error = () => {
    return (
        <>
        <NavBar /> 
        <CContainer>
            <h1 align='center'>404</h1>
            <p align='center'>This page does not exist.</p> 
        </CContainer>
        <Footer />
        </>
    );
};

export default Error;