import {Link} from 'react-router-dom'
import { useState, useEffect } from 'react';
import football from '../App.js'
import NavBar from '../NavBar.js';
import Footer from '../Footer';
import BannerAd from '../BannerAd.js';
import Card from '../Card';
import axios from 'axios'
const MLB = () => {
    const [mlb, setMLB] = useState([]);
    const apiKey = '143a0dc60904a41ee2ab555c349efb95'

    const mlbKey = 'baseball_mlb'
  const regions = 'us' // uk | us | eu | au. Multiple can be specified if comma delimited
  
  const markets = 'h2h,spreads,totals' // h2h | spreads | totals. Multiple can be specified if comma delimited
  
  const oddsFormat = 'american' // decimal | american
  
  const dateFormat = 'unix' // iso | unix

  useEffect(()=>{
    axios.get(`https://api.the-odds-api.com/v4/sports/${mlbKey}/odds`, {
      params: {
          apiKey,
          regions,
          markets,
          oddsFormat,
          dateFormat,
      }
  }
  )
  .then(response => {
      // response.data.data contains a list of live and 
      //   upcoming events and odds for different bookmakers.
      // Events are ordered by start time (live events are first)
      //console.log(JSON.stringify(response.data, null, 2))\
    
      setMLB(response.data)
  
  })
  .catch(error => {
      console.log('Error status', error.response.status)
     
  })
  },[])
  
    return (
        <>
        <NavBar />
        <BannerAd />
        <div className="App">
        
        {mlb && mlb.map((team) =>(
            <>
        <Card className='card' key={team.id} awayTeam={team.away_team} homeTeam={team.home_team} bookMakers={team.bookmakers} commenceTime={team.commence_time} sportKey={team.sport_key}/>
        </>)
        )}
        </div>
    <Footer/>
        </>
   );
}

export default MLB;
