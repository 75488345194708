import React from 'react'
import {CCard, CContainer, CTooltip, CCol, CRow, CCardTitle, CCardBody, CButton, CModal, CModalHeader ,CModalBody, CModalTitle, CPopover, CLink} from '@coreui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Card.css'
import SquareAd from './SquareAd';


function Card({awayTeam, homeTeam, bookMakers, commenceTime, sportKey}) {
    const [visible, setVisible] = useState(false)

    const color = {
      '--cui-btn-color' : '#ffffff' ,
      '--cui-btn-bg': '#9c2337' ,
      '--cui-btn-box-shadow' : '0px 0px 5px 5px #9c2337;',
      '--cui-btn-border-color' : '#9c2337' ,
    }
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const handleMouseOut = () => {
      setIsHovering(false);
    };    

    let page = ''
    switch(sportKey) {
      case 'americanfootball_nfl':
        page = '/nfl'
        break;
      case 'americanfootball_ncaaf':
        page = '/ncaaf'
        break;
      case 'americanfootball_cfl':
        page = '/cfl'
        break;
      case 'baseball_mlb':
        page = '/mlb'
        break;
      case 'soccer_fifa_world_cup':
        page = '/fifa'
        break;
      case 'soccer_epl':
        page = '/premier'
        break;
      case 'soccer_spain_la_liga':
        page = '/laliga'
        break;
      case 'soccer_germany_bundesliga':
        page = '/bundesliga'
        break;
      case 'soccer_italy_serie_a':
        page = '/seriea'
        break;
      case 'soccer_uefa_champs_league':
        page = '/champions'
        break;
      case 'soccer_uefa_europa_league':
        page = '/europa'
        break;
      case 'basketball_nba':
        page = '/nba'
        break;
      case 'basketball_wnba':
        page = '/wnba'
        break;
      default:
    }

  for (let x = 0; x <= bookMakers.length - 1; x++) {
    switch(bookMakers[x].key) {
      case 'unibet_us':
        //bookMakers[x].link = 'https://www.unibet.com'
        break;
      case 'twinspires':
        //bookMakers[x].link = 'https://www.twinspires.com'
        break;
      case 'draftkings':
        //bookMakers[x].link = 'https://draftkings.com'
        break;
      case 'fanduel':
        //bookMakers[x].link = 'https://fanduel.com'
        break;
      case 'lowvig':
        //bookMakers[x].link = 'https://www.lowvig.ag'
        break;
      case 'barstool':
        //bookMakers[x].link = 'https://www.barstoolsportsbook.com'
        break;
      case 'sugarhouse':
        //bookMakers[x].link = 'https://ct.playsugarhouse.com'
        break;
      case 'betonlineag':
        bookMakers[x].link = 'https://record.commissionkings.ag/_st3IwQq2CrSOmpBu5V19JWNd7ZgqdRLk/1/'
        break;
      case 'betrivers':
        //bookMakers[x].link = 'https://www.betrivers.com'
        break;
      case 'mybookieag':
        //bookMakers[x].link = 'https://www.mybookie.ag'
        break;
      case 'superbook':
        //bookMakers[x].link = 'https://www.superbook.com'
        break;
      case 'williamhill_us':
        bookMakers[x].link = 'https://promotion.williamhill.com/en-ca/sports/multisports/all/c500'
        break;
      case 'betmgm':
        //bookMakers[x].link = 'https://sports.betmgm.com/en/sports'
        break;
      case 'betus':
        bookMakers[x].link = 'https://record.revmasters.com/_FIJgthDPOyjUOsjNOfgKeWNd7ZgqdRLk/1/'
        break;
      case 'bovada':
        //bookMakers[x].link = 'https://www.bovada.lv'
        break;
      case 'circasports':
        //bookMakers[x].link = 'https://www.circasports.com'
        break;
      case 'wynnbet':
        //bookMakers[x].link = 'https://www.wynnbet.com'
        break;
      case 'betfair':
        //bookMakers[x].link = 'https://www.betfair.com'
        break;
      case 'pointsbetus':
        //bookMakers[x].link = 'https://pointsbet.com'
        break;
      case 'foxbet':
        //bookMakers[x].link = 'https://www.foxbet.com'
        break;
      case 'intertops':
        //bookMakers[x].link = 'https://sports.everygame.eu'
        break;
      default:
    }
  }


    const outcomes = [{name: '-', price: '-', point: '-'},{name: '-', price: '-', point: '-'}]
    outcomes.key = 'dummy';

    for (let i = 0; i <= bookMakers.length - 1; i++) {
      for (let j = 0; j <= 2; j++) {
          if(j == 0 && bookMakers[i].markets[j]?.key !== 'h2h') {            
            bookMakers[i].markets.splice(0, 0, {outcomes});
            break;
          }
          else if(j == 1 && bookMakers[i].markets[j]?.key !== 'spreads') {
            bookMakers[i].markets.splice(1, 0, {outcomes});
            break;
          }
          else if(j == 2 && bookMakers[i].markets[j]?.key !== 'totals') {
            bookMakers[i].markets.splice(2, 0, {outcomes});
            break;
          }
          else if(Array.isArray(bookMakers[i].markets[j].outcomes) && bookMakers[i].markets[j].outcomes.length) {
          }
          else {
            
          }
      }
    }
    
    let ML1Name = bookMakers[0]?.title
    let ML1Link = bookMakers[0]?.link
    let ML1Key = bookMakers[0]?.key
    let ML1 = bookMakers[0]?.markets[0]?.outcomes[0]?.price
  
    for (let y = 0; y <= bookMakers.length - 1; y++) {
      if (bookMakers[y]?.markets[0]?.outcomes[0]?.price === '-') {
      }
      else if (ML1 < bookMakers[y].markets[0]?.outcomes[0]?.price) {
        ML1 = bookMakers[y]?.markets[0]?.outcomes[0]?.price
        ML1Key = bookMakers[y].key
        ML1Link = bookMakers[y].link
        ML1Name = bookMakers[y].title
      }
    };
    
    let ML2Name = bookMakers[0]?.title
    let ML2Link = bookMakers[0]?.link
    let ML2Key = bookMakers[0]?.key
    let ML2 = bookMakers[0]?.markets[0]?.outcomes[1]?.price
  
    for (let y = 0; y <= bookMakers.length - 1; y++) {
      if (bookMakers[y]?.markets[0]?.outcomes[1]?.price === '-') {
      }
      else if (ML2 < bookMakers[y].markets[0]?.outcomes[1]?.price) {
        ML2 = bookMakers[y]?.markets[0]?.outcomes[1]?.price
        ML2Key = bookMakers[y]?.key
        ML2Link = bookMakers[y]?.link
        ML2Name = bookMakers[y]?.title
      }
    };
  
    let spread1Point = bookMakers[0]?.markets[1]?.outcomes[0]?.point
    let spread1Name = bookMakers[0]?.title
    let spread1Link = bookMakers[0]?.link
    let spread1Key = bookMakers[0]?.key
    let spread1 = bookMakers[0]?.markets[1]?.outcomes[0]?.price
  
    for (let y = 0; y <= bookMakers.length - 1; y++) {
      if (bookMakers[y]?.markets[1]?.outcomes[0]?.price === '-') {
      }
      else if (spread1 < bookMakers[y]?.markets[1]?.outcomes[0]?.price) {
        spread1 = bookMakers[y]?.markets[1]?.outcomes[0]?.price
        spread1Key = bookMakers[y]?.key
        spread1Link = bookMakers[y]?.link
        spread1Name = bookMakers[y]?.title
        spread1Point = bookMakers[y]?.markets[1]?.outcomes[0]?.point
      }
    };
  
    let spread2Point = bookMakers[0]?.markets[1]?.outcomes[1]?.point
    let spread2Name = bookMakers[0]?.title
    let spread2Link = bookMakers[0]?.link
    let spread2Key = bookMakers[0]?.key
    let spread2 = bookMakers[0]?.markets[1]?.outcomes[1]?.price
  
    for (let y = 0; y <= bookMakers.length - 1; y++) {
      if (bookMakers[y]?.markets[1]?.outcomes[1]?.price === '-') {
      }
      else if (spread2 < bookMakers[y]?.markets[1]?.outcomes[1]?.price) {
        spread2 = bookMakers[y]?.markets[1]?.outcomes[1]?.price
        spread2Key = bookMakers[y]?.key
        spread2Link = bookMakers[y]?.link
        spread2Name = bookMakers[y]?.title
        spread2Point = bookMakers[y]?.markets[1]?.outcomes[1]?.point
      }
    };
  
    let overPoint = bookMakers[0]?.markets[2]?.outcomes[0]?.point
    let overName = bookMakers[0]?.title
    let overLink = bookMakers[0]?.link
    let overKey = bookMakers[0]?.key
    let over = bookMakers[0]?.markets[2]?.outcomes[0]?.price
  
    for (let y = 0; y <= bookMakers.length - 1; y++) {
      if (bookMakers[y]?.markets[2]?.outcomes[0]?.price === '-') {
      }
      else if (over < bookMakers[y]?.markets[2]?.outcomes[0]?.price) {
        over = bookMakers[y]?.markets[2]?.outcomes[0]?.price
        overKey = bookMakers[y]?.key
        overLink = bookMakers[y]?.link
        overName = bookMakers[y]?.title
        overPoint = bookMakers[y]?.markets[2]?.outcomes[0]?.point
      }
    };
  
    let underPoint = bookMakers[0]?.markets[2]?.outcomes[1]?.point
    let underName = bookMakers[0]?.title
    let underLink = bookMakers[0]?.link
    let underKey = bookMakers[0]?.key
    let under = bookMakers[0]?.markets[2]?.outcomes[1]?.price
  
    for (let y = 0; y <= bookMakers.length - 1; y++) {
      if (bookMakers[y]?.markets[2]?.outcomes[1]?.price === '-') {
      }
      else if (over < bookMakers[y]?.markets[2]?.outcomes[1]?.price) {
        under = bookMakers[y]?.markets[2]?.outcomes[1]?.price
        underKey = bookMakers[y]?.key
        underLink = bookMakers[y]?.link
        underName = bookMakers[y]?.title
        underPoint = bookMakers[y]?.markets[2]?.outcomes[1]?.point
      }
    };

    //var gameDate = new Date(0);
     // The 0 there is the key, which sets the date to the epoch
    var gameDate = new Date(commenceTime * 1000).toLocaleDateString("en-US");
    var gameTime = new Date(commenceTime * 1000).toLocaleTimeString("en-US");
    //var timeConvert = parseFloat({commenceTime});
    //console.log({timeConvert})
    //let spread = bookMakers[0].markets[1].outcomes; map through all the different teams bookmakers and the market spreads for each
      
      //let euBookMakers =  bookMakers.filter(bookmaker => bookmaker.key === 'unibet' ||  bookmaker.key ===  'betonlineag' ||  bookmaker.key === 'marathonbet' ||  bookmaker.key === 'onexbet' ||  bookmaker.key === 'sport888' ||  bookmaker.key === 'pinnacle' ||  bookmaker.key === 'matchbook' ||  bookmaker.key === 'betclic' ||  bookmaker.key === 'mybookieag' ||  bookmaker.key === 'betfair' ||  bookmaker.key === 'betsson' ||  bookmaker.key === 'nordicbet' ||  bookmaker.key === 'intertops');
      let usBookMakers =  bookMakers.filter(bookmaker => bookmaker.key === 'draftkings' ||  bookmaker.key ===  'williamhill_us' ||  bookmaker.key === 'fanduel' ||  bookmaker.key === 'betonlineag' ||  bookmaker.key === 'foxbet' ||  bookmaker.key === 'lowvig' ||  bookmaker.key === 'bovada' ||  bookmaker.key === 'betus' ||  bookmaker.key === 'gtbets' ||  bookmaker.key === 'circasports' ||  bookmaker.key === 'betmgm' ||  bookmaker.key === 'betfair' ||  bookmaker.key === 'superbook' ||  bookmaker.key === 'betrivers'  ||  bookmaker.key === 'sugarhouse'  ||  bookmaker.key === 'twinspires'  ||  bookmaker.key === 'barstool'  ||  bookmaker.key === 'unibet'  ||  bookmaker.key === 'wynnbet'  ||  bookmaker.key === 'intertops' );
      //let ukBookMakers =  bookMakers.filter(bookmaker => bookmaker.key === 'unibet' ||  bookmaker.key ===  'mrgreen' ||  bookmaker.key === 'casumo' ||  bookmaker.key === 'marathonbet' ||  bookmaker.key === 'sport888' ||  bookmaker.key === 'ladbrokes' ||  bookmaker.key === 'virginbet' ||  bookmaker.key === 'livescorebet' ||  bookmaker.key === 'coral' ||  bookmaker.key === 'matchbook' ||  bookmaker.key === 'betclic' ||  bookmaker.key === 'betfair' ||  bookmaker.key === 'nordicbet');
      //let auBookMakers =  bookMakers.filter(bookmaker => bookmaker.key === 'unibet' ||  bookmaker.key ===  'sportsbet' ||  bookmaker.key === 'ladbrokes' ||  bookmaker.key === 'neds' ||  bookmaker.key === 'topsport' ||  bookmaker.key === 'tab' ||  bookmaker.key === 'pointsbetau' ||  bookmaker.key === 'betfair');
      
      //const [regionSelect, setRegionSelect] = useState([]);
      //setRegionSelect(usBookMakers);
      
      //setRegionSelect(usBookMakers)
      //let regionSelect = usBookMakers
      //onClick={setRegionSelect(usBookMakers)}
      let newArr = bookMakers.filter(bookmaker => bookmaker.key == 'draftkings' ||  bookmaker.key ==  'fanduel' ||  bookmaker.key == 'bovada');
    return (
    <CCard className='card'> 
   
  <CCardBody onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
  <CRow>
    <CCol xs={10}>
    <CCardTitle className='title'><img src={`images/${sportKey}/${awayTeam}.png`} width='auto' height={20} alt=''></img> {awayTeam} @</CCardTitle>
     
    <CCardTitle className='title'><img src={`images/${sportKey}/${homeTeam}.png`} width='auto' height={20} alt=''></img> {homeTeam}{' | '}{gameDate} {gameTime}</CCardTitle>
     
    </CCol>
    <CCol xs= {2}>
      <CCardTitle align='end' className='title'>
      <Link to={page}>
      <img src={`images/gametype/${sportKey}.png`} height={60}></img>
      </Link>
      </CCardTitle>
    </CCol>
  </CRow>
  <hr></hr>
    
  <CContainer>
    <CRow>
      <CCol align='center'>BOOKMAKER</CCol>
      <CCol align='center'>MONEYLINE</CCol>
      <CCol align='center'>SPREADS</CCol>
      <CCol align='center'>TOTALS</CCol>
    </CRow>
              
    {newArr.map((item) =>(
      <>
      <hr></hr>
      <CRow>
        <CCol align='center'>
          <a href={item.link} target='_blank'>
          <p><img src={`images/us-bookmakers/${item.key}.png`} width={50} height={50}></img></p>
          </a>
          <p sm="auto">{item.title}</p>
        </CCol>
        <CCol align='center'>
          <img src={`images/${sportKey}/${(item.markets[0]?.outcomes[0]?.name)}.png`} width='auto' height={20} alt=''></img> {(item.markets[0]?.outcomes[0]?.price)}<br></br>
          <hr></hr>
          <img src={`images/${sportKey}/${(item.markets[0]?.outcomes[1]?.name)}.png`} width='auto' height={20} alt=''></img> {(item.markets[0]?.outcomes[1]?.price)}
        </CCol>
        <CCol align='center'>
          {(item.markets[1]?.outcomes[0]?.point)} {(item.markets[1]?.outcomes[0]?.price)} <br></br>
          <hr></hr>
          {(item.markets[1]?.outcomes[1]?.point)} {(item.markets[1]?.outcomes[1]?.price)}
        </CCol>
        <CCol align='center'>
          {(item.markets[2]?.outcomes[0]?.name.charAt(0))} {(item.markets[2]?.outcomes[0]?.point)} {(item.markets[2]?.outcomes[0]?.price)}<br></br>
          <hr></hr>
          {(item.markets[2]?.outcomes[1]?.name.charAt(0))} {(item.markets[2]?.outcomes[1]?.point)} {(item.markets[2]?.outcomes[1]?.price)}
        </CCol>

      </CRow> 
      </>
    ))}
    <SquareAd />
  </CContainer>
      
    {isHovering && (
      <CButton style={color} color='danger' className="moreinfo d-grid col-4 mx-auto" onClick={() => setVisible(!visible)}>More Books</CButton>
    )}
      <CModal size="lg" alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
      <CModalHeader className='modaltitle'>
        <CModalTitle><img src={`images/${sportKey}/${awayTeam}.png`} width='auto' height={30} alt=''></img> {awayTeam} {`@ `}<img src={`images/${sportKey}/${homeTeam}.png`} width='auto' height={30} alt=''></img> {homeTeam} {'| '}{gameDate} {gameTime}</CModalTitle> 
      </CModalHeader>
      <CModalBody>
      <CContainer>
    <CRow>
      <CCol align='center'>BOOKMAKER</CCol>
      <CCol align='center'>MONEYLINE</CCol>
      <CCol align='center'>SPREADS</CCol>
      <CCol align='center'>TOTALS</CCol>
    </CRow>
    <hr></hr>
    <CRow className='bestodds'>
      <CCol align='center'><img src={'images/bestodds.png'} width={60} height={60}/><br></br>This game's best odds!</CCol>
      <CCol align='center'>
      <CTooltip content={ML1Name} placement="left"><a href={ML1Link} target='_blank'><img src={`images/us-bookmakers/${ML1Key}.png`} height={30}/></a></CTooltip> {ML1} 
        <hr></hr>
        <CTooltip content={ML2Name} placement="left"><a href={ML2Link} target='_blank'><img src={`images/us-bookmakers/${ML2Key}.png`} height={30}/></a></CTooltip> {ML2}
      </CCol>
      <CCol align='center'>
        <CTooltip content={spread1Name} placement="left"><a href={spread1Link} target='_blank'><img src={`images/us-bookmakers/${spread1Key}.png`} height={30}/></a></CTooltip> {spread1Point} {spread1}
        <hr></hr>
        <CTooltip content={spread2Name} placement="left"><a href={spread2Link} target='_blank'><img src={`images/us-bookmakers/${spread2Key}.png`} height={30}/></a></CTooltip> {spread2Point} {spread2}
      </CCol>
      <CCol align='center'>
        <CTooltip content={overName} placement="left"><a href={overLink} target='_blank'><img src={`images/us-bookmakers/${overKey}.png`} height={30}/></a></CTooltip> O {overPoint} {over}
        <hr></hr>
        <CTooltip content={underName} placement="left"><a href={underLink} target='_blank'><img src={`images/us-bookmakers/${underKey}.png`} height={30}/></a></CTooltip> U {underPoint} {under}
      </CCol>
    </CRow>
              
    {bookMakers.map((item) =>(
      <>
      <hr></hr>
      <CRow>
        <CCol align='center'>
          <a href={item.link} target='_blank'>
          <p><img src={`images/us-bookmakers/${item.key}.png`} width={50} height={50}></img></p>
          </a>
          <p sm="auto">{item.title}</p>
        </CCol>
        <CCol align='center'>
          <img src={`images/${sportKey}/${(item.markets[0]?.outcomes[0]?.name)}.png`} width='auto' height={20} alt=''></img> {(item.markets[0]?.outcomes[0]?.price)}<br></br>
          <hr></hr>
          <img src={`images/${sportKey}/${(item.markets[0]?.outcomes[1]?.name)}.png`} width='auto' height={20} alt=''></img> {(item.markets[0]?.outcomes[1]?.price)}
        </CCol>
        <CCol align='center'>
          {(item.markets[1]?.outcomes[0]?.point)} {(item.markets[1]?.outcomes[0]?.price)} <br></br>
          <hr></hr>
          {(item.markets[1]?.outcomes[1]?.point)} {(item.markets[1]?.outcomes[1]?.price)}
        </CCol>
        <CCol align='center'>
          {(item.markets[2]?.outcomes[0]?.name.charAt(0))} {(item.markets[2]?.outcomes[0]?.point)} {(item.markets[2]?.outcomes[0]?.price)}<br></br>
          <hr></hr>
          {(item.markets[2]?.outcomes[1]?.name.charAt(0))} {(item.markets[2]?.outcomes[1]?.point)} {(item.markets[2]?.outcomes[1]?.price)}
        </CCol>

      </CRow> 
      </>
    ))}
  </CContainer>
      </CModalBody>
      
      </CModal>
  </CCardBody>
  </CCard>

  )
}
export default Card
