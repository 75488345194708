import React from 'react'
import {CNavbar, CContainer, CNavbarBrand, CNavbarToggler, CCol, CCollapse,CNavbarNav,CNavItem,CNavLink, CForm,CFormInput, CButton, CDropdown, CDropdownToggle, CDropdownItem, CDropdownDivider, CDropdownMenu,COffcanvas,COffcanvasHeader,COffcanvasTitle,CCloseButton,COffcanvasBody, CDropdownHeader, CRow} from '@coreui/react';
import { useState } from 'react';
import './NavBar.css'
//import odds from './pages/Home.js'
//import ukBookMakers from './Card';
//import euBookMakers from './Card';
//import auBookMakers from './Card';
//import setRegionSelect from './Card';
//import regionSelect from './Card';

function NavBar() {
  const [visible, setVisible] = useState(false);
  const [visibleScrolling, setVisibleScrolling] = useState(false)
  const [visibleDropdown, setVisibleDropdown] = useState(false)
  const [calc, setCalc] = useState({
    bet: '',
    odd: '',
    result: '',
    total: '',
    text: 'Enter your bet amount and odds, then click "Calculate"',
  });

  //let euBookMakers =  Card.bookMakers.filter(bookmaker => bookmaker.key === 'unibet' ||  bookmaker.key ===  'betonlineag' ||  bookmaker.key === 'marathonbet' ||  bookmaker.key === 'onexbet' ||  bookmaker.key === 'sport888' ||  bookmaker.key === 'pinnacle' ||  bookmaker.key === 'matchbook' ||  bookmaker.key === 'betclic' ||  bookmaker.key === 'mybookieag' ||  bookmaker.key === 'betfair' ||  bookmaker.key === 'betsson' ||  bookmaker.key === 'nordicbet' ||  bookmaker.key === 'intertops');
  //let usBookMakers =  Card.bookMakers.filter(bookmaker => bookmaker.key === 'draftkings' ||  bookmaker.key ===  'williamhill_us' ||  bookmaker.key === 'fanduel' ||  bookmaker.key === 'betonlineag' ||  bookmaker.key === 'foxbet' ||  bookmaker.key === 'lowvig' ||  bookmaker.key === 'bovada' ||  bookmaker.key === 'betus' ||  bookmaker.key === 'gtbets' ||  bookmaker.key === 'circasports' ||  bookmaker.key === 'betmgm' ||  bookmaker.key === 'betfair' ||  bookmaker.key === 'superbook' ||  bookmaker.key === 'betrivers'  ||  bookmaker.key === 'sugarhouse'  ||  bookmaker.key === 'twinspires'  ||  bookmaker.key === 'barstool'  ||  bookmaker.key === 'unibet'  ||  bookmaker.key === 'wynnbet'  ||  bookmaker.key === 'intertops' );
  //let ukBookMakers =  Card.bookMakers.filter(bookmaker => bookmaker.key === 'unibet' ||  bookmaker.key ===  'mrgreen' ||  bookmaker.key === 'casumo' ||  bookmaker.key === 'marathonbet' ||  bookmaker.key === 'sport888' ||  bookmaker.key === 'ladbrokes' ||  bookmaker.key === 'virginbet' ||  bookmaker.key === 'livescorebet' ||  bookmaker.key === 'coral' ||  bookmaker.key === 'matchbook' ||  bookmaker.key === 'betclic' ||  bookmaker.key === 'betfair' ||  bookmaker.key === 'nordicbet');
  //let auBookMakers =  Card.bookMakers.filter(bookmaker => bookmaker.key === 'unibet' ||  bookmaker.key ===  'sportsbet' ||  bookmaker.key === 'ladbrokes' ||  bookmaker.key === 'neds' ||  bookmaker.key === 'topsport' ||  bookmaker.key === 'tab' ||  bookmaker.key === 'pointsbetau' ||  bookmaker.key === 'betfair');
  
  //const [regionSelect, setRegionSelect] = useState([]);
  //setRegionSelect(usBookMakers);
  //console.log(regionSelect);
  const dropDown = {
    '--cui-dropdown-min-width' : '40rem',
    '--cui-dropdown-header-color' : '#9c2337',
  }

  const color = {
    '--cui-btn-color' : '#9c2337' ,
    '--cui-btn-bg': 'transparent' ,
    '--cui-btn-box-shadow' : '0px 0px 5px 5px #9c2337;',
    '--cui-btn-border-color' : '#9c2337' ,
  }

  const offCanvas = {
    '--cui-offcanvas-width' : '15rem'
  }
  
  const handleChange = (e) => {
    setCalc({ ...calc, [e.target.name]: e.target.value });
  };

  var unwantedCharacter = '$'
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    while( calc.bet.charAt(0) == unwantedCharacter ) calc.bet = calc.bet.substring(1);
    if (calc.odd <= 0) {
      calc.result = (100/Math.abs(calc.odd)) * calc.bet;
      calc.total = (Math.abs(calc.bet) + Math.abs(calc.result));
      setCalc({ ...calc, text: `Your bet could win you $${parseFloat(calc.result).toFixed(2)} with a total payout of $${parseFloat(calc.total).toFixed(2)} (including your original bet)` });
    }
    else {
      calc.result = (Math.abs(calc.odd)/100) * calc.bet;
      calc.total = (Math.abs(calc.bet) + Math.abs(calc.result));
      setCalc({ ...calc, text: `Your bet could win you $${parseFloat(calc.result).toFixed(2)} with a total payout of $${parseFloat(calc.total).toFixed(2)} (including your original bet)` });
    }
}
  return (
  <>
    <CNavbar expand="lg" colorScheme="light" className="navbar" placement="sticky-top">
      <CContainer fluid>
        <CNavbarBrand href="/"><img src='images/logo-main.png' width="200" height="46"></img></CNavbarBrand>
        <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={() => setVisible(!visible)}
        />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav className="me-auto mb-2 mb-lg-0">
            <CDropdown variant="nav-item" popper={false}>
              <CDropdownToggle color="danger">Sports</CDropdownToggle>
              <CDropdownMenu style={dropDown} className='sports'>
                <CRow>
                  <CCol md={4}>
                <CDropdownHeader>Football</CDropdownHeader>
                <CDropdownItem href="/nfl">NFL</CDropdownItem>
                <CDropdownItem href="/ncaaf">NCAA</CDropdownItem>
                <CDropdownItem href="/cfl">CFL</CDropdownItem>
                <CDropdownDivider />
                <CDropdownHeader>Baseball</CDropdownHeader>
                <CDropdownItem href="/mlb">MLB</CDropdownItem>
                <CDropdownDivider />
                <CDropdownHeader>Combat</CDropdownHeader>
                <CDropdownItem href="#" disabled>UFC/MMA</CDropdownItem>
                  </CCol>
                  <CCol md={4}>
                <CDropdownHeader>Soccer</CDropdownHeader>
                <CDropdownItem href="/fifa">FIFA World Cup</CDropdownItem>
                <CDropdownItem href="/premier">Premier League</CDropdownItem>
                <CDropdownItem href="/laliga">LaLiga</CDropdownItem>
                <CDropdownItem href="/bundesliga">Bundesliga</CDropdownItem>
                <CDropdownItem href="/seriea">Serie A</CDropdownItem>
                <CDropdownItem href="/champions">Champions League</CDropdownItem>
                <CDropdownItem href="/europa">Europa League</CDropdownItem>
                  </CCol>
                  <CCol md={4}>
                <CDropdownHeader>Basketball</CDropdownHeader>
                <CDropdownItem href="/nba">NBA</CDropdownItem>
                <CDropdownItem href="/wnba" >WNBA</CDropdownItem>
                <CDropdownItem href="#" disabled>Euro League</CDropdownItem>
                <CDropdownItem href="#" disabled>NCAA</CDropdownItem>
                <CDropdownDivider />
                <CDropdownHeader>Cricket</CDropdownHeader>
                <CDropdownItem href="#" disabled>Big Bash</CDropdownItem>
                <CDropdownItem href="#" disabled>ICC World Cup</CDropdownItem>
                <CDropdownItem href="#" disabled>IPL</CDropdownItem>
                <CDropdownItem href="#" disabled>One Day Internationals</CDropdownItem>
                <CDropdownItem href="#" disabled>Test Matches</CDropdownItem>
                </CCol>
                </CRow>
              </CDropdownMenu>
            </CDropdown>
            <CNavItem>
              <CNavLink href='/advert'>
                ADVERTISE WITH US
              </CNavLink>
            </CNavItem>
          </CNavbarNav>
          <CNavbarNav className='justify-content-right'>
            <CNavItem>
              <CNavLink className='calc' onClick={() => setVisibleScrolling(true)}>
              Calculator
              </CNavLink>
            </CNavItem>
            </CNavbarNav>
          <CForm className="d-flex">
            <CFormInput type="search" style ={color} className="search me-2" placeholder="Search"/>
            <CButton type="submit" style={color} color="danger" variant="outline">
              Search
            </CButton>
          </CForm>
        </CCollapse>
      </CContainer>
    </CNavbar>
    <COffcanvas style={offCanvas} backdrop={false} placement="start" scroll visible={visibleScrolling} onHide={() => setVisibleScrolling(false)}>
      <COffcanvasHeader>
        <COffcanvasTitle>Bet Calculator</COffcanvasTitle>
        <CCloseButton className="text-reset" onClick={() => setVisibleScrolling(false)}/>
      </COffcanvasHeader>
      <COffcanvasBody>
        <CForm onSubmit={handleSubmit}>
        <CFormInput type='text' name='bet' placeholder='Type bet amount here...' label='Bet Amount' value={calc.bet} onChange={handleChange}></CFormInput>
        <br></br>
        <CFormInput type='text' name='odd' placeholder='Type odds here...' label='Odds' value={calc.odd} onChange={handleChange}></CFormInput>
        <br></br>
        <CButton style={color} color='danger' type='submit'>Calculate</CButton>
        </CForm>
        <hr></hr>
        <p>{calc.text}</p>
      </COffcanvasBody>
    </COffcanvas>
  </>
)
}

export default NavBar