import logo from './logo.svg';
import './App.css';
import NavBar from './NavBar';
import Footer from './Footer';

import MLB from './pages/MLB';
import NFL from './pages/NFL';
import CFL from './pages/CFL';
import NCAAF from './pages/NCAAF';
import Error from './pages/Error';
import Home from './pages/Home';
import Advert from './pages/Advert';
import FIFA from './pages/FIFA';
import Premier from './pages/Premier';
import LaLiga from './pages/LaLiga';
import BundesLiga from './pages/BundesLiga';
import SerieA from './pages/SerieA';
import Champions from './pages/Champions';
import Europa from './pages/Europa';
import MMA from './pages/MMA';
import NBA from './pages/NBA';
import WNBA from './pages/WNBA';
import {Router, BrowserRouter, Link, Routes, Route} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';
import Card from './Card';
import './Card.css'
import '@coreui/coreui/dist/css/coreui.min.css'
function App() {
  /*
  const [football, setFootball] = useState([]);
  const [baseball, setBaseball] = useState([]);
  const apiKey = '143a0dc60904a41ee2ab555c349efb95'

  const footballKey = 'americanfootball_nfl' // *Was upcoming before* use the sport_key from the /sports endpoint below, or use 'upcoming' to see the next 8 games across all sports
  
  const baseballKey = 'baseball_mlb'
  const regions = 'us,uk,eu,au' // uk | us | eu | au. Multiple can be specified if comma delimited
  
  const markets = 'h2h,spreads,totals' // h2h | spreads | totals. Multiple can be specified if comma delimited
  
  const oddsFormat = 'american' // decimal | american
  
  const dateFormat = 'unix' // iso | unix
  useEffect(()=>{
    axios.get(`https://api.the-odds-api.com/v4/sports/${footballKey}/odds`, {
      params: {
          apiKey,
          regions,
          markets,
          oddsFormat,
          dateFormat,
      }
  }
  )
  .then(response => {
      // response.data.data contains a list of live and 
      //   upcoming events and odds for different bookmakers.
      // Events are ordered by start time (live events are first)
      //console.log(JSON.stringify(response.data, null, 2))\
      console.log(response.data)
      setFootball(response.data)
  
  })
  .catch(error => {
      console.log('Error status', error.response.status)
      console.log(error.response.data)
  })
  },[])
  useEffect(()=>{
    axios.get(`https://api.the-odds-api.com/v4/sports/${baseballKey}/odds`, {
      params: {
          apiKey,
          regions,
          markets,
          oddsFormat,
          dateFormat,
      }
  }
  )
  .then(response => {
      // response.data.data contains a list of live and 
      //   upcoming events and odds for different bookmakers.
      // Events are ordered by start time (live events are first)
      //console.log(JSON.stringify(response.data, null, 2))\
      console.log(response.data)
      setBaseball(response.data)
  
  })
  .catch(error => {
      console.log('Error status', error.response.status)
      console.log(error.response.data)
  })
  },[])

  // let odds = Object.assign(football,baseball);
  const odds = [...football, ...baseball]
  // odds.sort((a, b) => (a.commenceTime > b.commenceTime) ? 1 : -1)
  
  odds.sort((a, b) => {
    return a.commence_time - b.commence_time;
  });
  */
  return (
   
    <BrowserRouter>
      <Routes>
  
        <Route path = '/' element = {<Home />} />
        <Route path = '/mlb' element = {<MLB />} />
        <Route path = '/nfl' element = {<NFL />} />
        <Route path = '/cfl' element = {<CFL />} />
        <Route path = '/ncaaf' element = {<NCAAF />} />
        <Route path = '/fifa' element = {<FIFA />} />
        <Route path = '/premier' element = {<Premier />} />
        <Route path = '/laliga' element = {<LaLiga />} />
        <Route path = '/bundesliga' element = {<BundesLiga />} />
        <Route path = '/seriea' element = {<SerieA />} />
        <Route path = '/champions' element = {<Champions />} />
        <Route path = '/europa' element = {<Europa />} />
        <Route path = '/MMA' element = {<MMA />} />
        <Route path = '/nba' element = {<NBA />} />
        <Route path = '/wnba' element = {<WNBA />} />
        <Route path = '*' element = {<Error />} />
        <Route path ='/advert' element = {<Advert />} />
      </Routes>
    </BrowserRouter>
       
   
  );
}

export default App;
