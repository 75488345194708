import React from "react";
import {CFooter} from '@coreui/react';
import './Footer.css';

export default function Footer() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

return (
  <CFooter className='footer'>
  <div>
    <span> {getCurrentYear()} SportsOdds&copy;</span>
  </div>
  <div>
    <span>More features coming soon!</span>
  </div>
</CFooter>
);
}