import { CContainer, CImage } from '@coreui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import './BannerAd.css';

function BannerAd() {

    return (
        <CContainer className= 'bannercontain' fluid align='center'>
          <a href='https://tinylogcabins.net' target='_blank'>
          <CImage className='bannerimg'fluid rounded src='images/ad-banner.png' />
          </a>
        </CContainer>
    );
  }


export default BannerAd
