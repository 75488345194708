import { CContainer, CImage, CCol, CRow } from '@coreui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import './SquareAd.css';

function SquareAd() {

    return (
      
        <CContainer className= 'squarecontain' fluid>
          <CRow>
            <CCol xs={4}>
              <CContainer align='start'>
          <a href='https://record.revmasters.com/_FIJgthDPOyhP9k1cva7CdGNd7ZgqdRLk/1/' target='_blank'>
          <CImage className='squareimg'fluid rounded  align='start' src='https://media.revmasters.com/uploads/001x02-250x250-join.gif' />
          </a>
          </CContainer>
          </CCol>
          <CCol xs={4}>
          <CContainer align='center'>
          <a href='https://record.commissionkings.ag/_st3IwQq2CrRqkvbhkiKqDGNd7ZgqdRLk/1/' target='_blank'>
          <CImage className='squareimg'fluid rounded  align='start' src='images/betonline_squaread.gif' />
          </a>
          </CContainer>
          </CCol>
          <CCol xs={4}>
          <CContainer align='end'>
          <Link to='/advert'>
          <CImage className='squareimg'fluid rounded align='end' src='images/ad-square.png' />
          </Link>
          </CContainer>
          </CCol>
          </CRow>
        </CContainer>
    );
  }


export default SquareAd